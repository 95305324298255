//
// Main
//

// Body
body {
	background-color: var(--kt-page-bg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% get($page-bg-image-height, desktop);

	&.page-bg-image-lg {
		background-size: 100% get($page-bg-image-height-lg, desktop);
	}
}

// @include theme-light() {
// 	body:not(.app-blank) {
// 		background-image: url('../media/patterns/header-bg.jpg');
// 	}
// }

// @include theme-dark() {
// 	body:not(.app-blank) {
// 		background-image: url('../media/patterns/jetblue-bg.png');
// 	}
// }

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

.page-bg {
	background-color: var(--kt-page-bg) !important;
}

// Nav custom
.nav-custom {
    .nav-link {
        color: $white !important;
        opacity: 0.7;

        &.active {
            opacity: 1;
            border-bottom: 2px solid $white !important;
        }

		&:hover {
            opacity: 1;
            border-bottom-color: transparent !important;
        }        
    }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease,  margin-right get($aside-config, transition-speed) ease;

		// Fixed header mode
		.header-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, desktop, default, height);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Body
	body {
		background-size: 100% get($page-bg-image-height, tablet-and-mobile);

		&.page-bg-image-lg {
			background-size: 100% get($page-bg-image-height-lg, tablet-and-mobile);
		}
	}

	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, tablet-and-mobile, default, height);
		}
	}
}
